import { Box, Stack, Typography, useTheme } from '@mui/material';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import CoursesNavigator from './components/CoursesNavigator';
import CourseView from './components/CourseView';

// redux
import { CancelOutlined } from '@mui/icons-material';
import LoadingState from 'components/new_components/LoadingState';
import { connect } from 'react-redux';
import { GetAllTrainingCourses } from 'store/actions/trainingActions';

//userguide
import { Tour } from 'antd';
import AppArticle from 'components/new_components/AppArticle';
import { UserGuideHeader } from 'components/new_components/AppUserGuide';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';

//translations
import { useTranslation } from 'react-i18next';

const Training = (props) => {
    // props
    const { GetAllTrainingCourses, all_training_courses, userguide_info, all_article_header } = props;
    const theme = useTheme();
    // state
    const [currentCourse, setCurrentCourse] = useState(0);
    const [loading, setLoading] = useState(false);
    const [openUserGuide, setOpenUserGuide] = useState(false);
    const [openArticle, setOpenArticle] = useState(false);

    //userguide
    const { updateOneUserGuide, toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);
    const ref1 = useRef(null);
    const ref2 = useRef(null);

    const STEPS_LENGTH = 2;

    const steps = [
        {
            title: <UserGuideHeader title="Course Display" id={1} length={STEPS_LENGTH} />,
            description: 'All training courses are shown here.',
            target: () => ref1.current,
            placement: 'bottomLeft',
        },
        {
            title: <UserGuideHeader title="Add Employee to Training" id={2} length={STEPS_LENGTH} />,
            description: 'Here you can add an employee to training',
            target: () => ref2.current,
            placement: 'bottomRight',
        },
    ];

    //translation
    const { t } = useTranslation('training');

    //useMemo
    const oneArticle = useMemo(
        () => all_article_header?.find((article) => article?.name === 'Training'),
        [all_article_header]
    );

    // functions
    const getAllTrainingCourses = async () => {
        setLoading(true);
        const res = await GetAllTrainingCourses();
        setLoading(false);
        if (!res?.success) {
            toast.error("Something went wrong, couldn't fetch courses.");
        }
    };

    //UserGuide
    const OpenUserGuide = () => {
        setOpenUserGuide(true);
    };
    const CloseUserGuide = () => {
        updateOneUserGuide({ training: true });
        setOpenUserGuide(false);
    };
    const OpenArticle = () => {
        setOpenArticle(true);
    };
    const CloseArticle = () => {
        setToggleUserGuide({ ...toggleUserGuide, employee: false });
        setOpenArticle(false);
    };

    // useEffect
    useEffect(() => {
        getAllTrainingCourses();
    }, []);

    useEffect(() => {
        // console.log(toggleUserGuide, userguide_info);
        toggleUserGuide.employee ? OpenArticle() : null;
        !userguide_info ? OpenUserGuide() : null;
    }, [userguide_info, toggleUserGuide.employee]);

    return (
        <Box sx={{ m: -3, width: 'calc(100% + 48px)', height: 'calc(100% + 48px)' }}>
            <Stack
                direction="row"
                sx={{
                    width: '100%',
                    height: '100%',
                }}
            >
                {loading ? (
                    <LoadingState />
                ) : all_training_courses && all_training_courses?.length ? (
                    <>
                        <CoursesNavigator current={currentCourse} onCourseChange={setCurrentCourse} ref1={ref1} />
                        <CourseView current={currentCourse} ref2={ref2} />
                    </>
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            flexDirection: 'column',
                            height: '100vh',
                            width: '100%',
                        }}
                    >
                        <CancelOutlined sx={{ color: theme.palette.primary[900], fontSize: 120, mb: 2 }} />
                        <Typography
                            sx={{
                                fontWeight: 700,
                                fontSize: 20,
                                color: theme.palette.primary[900],
                                my: 1,
                            }}
                        >
                            {t('courseContent1')}
                        </Typography>
                        <Typography
                            sx={{ maxWidth: 650, mx: 'auto', textAlign: 'center', color: theme.palette.gray[40] }}
                        >
                            {t('courseContent2')}
                        </Typography>
                    </Box>
                )}
            </Stack>
            <Tour open={openUserGuide} onClose={CloseUserGuide} steps={steps} />
            <AppArticle
                open={openArticle}
                handleClose={CloseArticle}
                title="Training"
                headerId={oneArticle?.id}
                icon={true}
            />
        </Box>
    );
};
const mapStateToProps = (state) => ({
    all_training_courses: state?.trainingReducers?.all_training_courses,
    userguide_info: state?.guideReducers?.userguide_info?.[0]?.training,
    all_article_header: state?.articlesReducers?.all_article_header,
});
export default connect(mapStateToProps, { GetAllTrainingCourses })(Training);
