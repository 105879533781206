import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Modal, Row, Col, Input, Button } from 'antd';

// redux
import { connect } from 'react-redux';
import { CreateEvidence, UpdateEvidence } from 'store/actions/adminActions';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const AddEvidenceModal = (props) => {
    const {
        open,
        handleClose,
        tag,
        CreateEvidence,
        UpdateEvidence,
        payload,
        titleLabel,
        descLabel,
        // segmentLabel,
        // groupIdLabel,
        oneEvidence,
        type,
        title,
    } = props;
    // state
    const [loading, setLoading] = useState(false);
    const [segments, setSegments] = useState(['']);
    const [data, setData] = useState({});
    const [body, setBody] = useState('');

    const modules = {
        toolbar: {
            container: [
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                ['image', 'link'],
                [
                    {
                        color: [
                            '#000000',
                            '#e60000',
                            '#ff9900',
                            '#ffff00',
                            '#008a00',
                            '#0066cc',
                            '#9933ff',
                            '#ffffff',
                            '#facccc',
                            '#ffebcc',
                            '#ffffcc',
                            '#cce8cc',
                            '#cce0f5',
                            '#ebd6ff',
                            '#bbbbbb',
                            '#f06666',
                            '#ffc266',
                            '#ffff66',
                            '#66b966',
                            '#66a3e0',
                            '#c285ff',
                            '#888888',
                            '#a10000',
                            '#b26b00',
                            '#b2b200',
                            '#006100',
                            '#0047b2',
                            '#6b24b2',
                            '#444444',
                            '#5c0000',
                            '#663d00',
                            '#666600',
                            '#003700',
                            '#002966',
                            '#3d1466',
                        ],
                    },
                ],
            ],
        },
    };

    // functions
    const closeAll = () => {
        setData({});
        setBody('');
        handleClose();
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        // const groupId = payload?.[groupIdLabel];
        setLoading(true);
        const res = oneEvidence
            ? await UpdateEvidence(
                  {
                      ...data,
                      tags: tag,
                      lifecycle: payload?.lifecycle,
                      lifecycle_sub_no: payload?.lifecycle_sub_no,
                      segments: segments?.map((segment, index) => {
                          console.log({ segment });
                          return {
                              [`segment_${index + 1}`]: segment?.[`segment_${index + 1}`] || JSON?.parse(segment),
                          };
                      }),
                  },
                  oneEvidence?.id,
                  tag,
                  title
              )
            : await CreateEvidence(
                  {
                      ...data,
                      tags: tag,
                      lifecycle: payload?.lifecycle,
                      lifecycle_sub_no: payload?.lifecycle_sub_no,
                      segments: segments?.map((segment, index) => {
                          return {
                              [`segment_${index + 1}`]: JSON?.parse(segment),
                          };
                      }),
                  },
                  tag,
                  title
              );
        setLoading(false);
        if (res.success) {
            toast.success(oneEvidence ? 'Evidence Updated Successfully' : 'Evidence Added Successfully');
            closeAll();
        } else {
            toast.error(res?.message);
        }
    };
    const handleTextChange = (e) => {
        const { name, value } = e.target;
        setData({ ...data, [name]: value });
    };
    useEffect(() => {
        if (oneEvidence) {
            setData({
                lifecycle_title: oneEvidence?.lifecycle_title,
                lifecycle_description: oneEvidence?.lifecycle_description,
                lifecycle_number: oneEvidence?.lifecycle_number,
            });
            type !== 'add' && setBody(oneEvidence?.lifecycle_description);
        } else {
            setData({});
            setBody('');
        }
    }, [oneEvidence]);

    useEffect(() => {
        if (body) {
            setData({ ...data, [descLabel]: body });
        }
    }, [body]);

    const handleSegmentChange = (event, index) => {
        const newSegments = segments || [];
        newSegments[index] = event?.target?.value;
        setSegments([...newSegments]);
    };

    useEffect(() => {
        if (oneEvidence?.segments?.length > 0) {
            setSegments(oneEvidence?.segments);
        }
    }, [oneEvidence]);
    return (
        <Modal
            title={`${type === 'add' ? 'Add' : 'Edit'} Evidence`}
            open={open}
            onCancel={closeAll}
            footer={null}
            destroyOnClose={true}
        >
            <form encType="multipart/form-data" onSubmit={handleSubmit}>
                <Row justify="end">
                    <Col span={24}>
                        <div className="form-group" style={{ marginBottom: '1rem' }}>
                            <label htmlFor={titleLabel}>Evidence title</label>
                            <Input
                                type="text"
                                size="large"
                                name={titleLabel}
                                id={titleLabel}
                                onChange={handleTextChange}
                                value={data?.[titleLabel] || ''}
                                required
                            />
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="form-group" style={{ marginBottom: '1rem' }}>
                            <label htmlFor={descLabel}>Evidence description</label>
                            <ReactQuill theme="snow" onChange={setBody} value={body || ''} modules={modules} />
                        </div>
                    </Col>{' '}
                    {segments?.map((segment, index) => {
                        return (
                            <Col span={24} key={index}>
                                <div className="form-group" style={{ marginBottom: '1rem' }}>
                                    <label htmlFor={`segment ${index + 1}`}>No of evidences</label>
                                    <Input
                                        type="number"
                                        size="large"
                                        name={`Segment_${index + 1}`}
                                        id={`Segment_${index + 1}`}
                                        placeholder="No of evidences"
                                        onChange={(event) => handleSegmentChange(event, index)}
                                        value={segment?.[`segment_${index + 1}`]}
                                        required
                                    />
                                </div>
                            </Col>
                        );
                    })}
                    <Col>
                        <Button
                            className="ant-btn"
                            htmlType="submit"
                            type="primary"
                            loading={loading}
                            style={{ marginTop: '3rem' }}
                        >
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};
const mapStateToProps = (state) => {
    return {
        all_documents_by_alttags: state?.adminReducers?.all_documents_by_alttags,
        all_pcidss4point0_documents_by_alttags: state?.adminReducers?.all_pcidss4point0_documents_by_alttags,
    };
};
export default connect(mapStateToProps, { CreateEvidence, UpdateEvidence })(AddEvidenceModal);
