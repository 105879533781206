import CustomSwitch from 'components/new_components/CustomSwitch';
import CustomTooltip from 'components/new_components/CustomTooltip';
import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import FirstSection from './components/firstSection/FirstSection';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';
import {
    GetMerchantNDPRGeneralSummary,
    GetMerchantKDPAGeneralSummary,
    GetMerchantPcidss4point0OverviewSummary,
    GetMerchantPCISLCGeneralSummary,
    GetMerchantSoc2GeneralSummary,
    GetMerchantOverviewSummary,
    GetDashboardCompliance,
} from 'store/actions/dashboardActions';
import { useDispatch, useSelector } from 'react-redux';
import { Tour, Typography } from 'antd';
import { Box, Loader2 } from 'lucide-react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from '@mui/material';
import ComplianceHealthSuggestions from './smartDashboardAI/ComplianceHealthSuggestions';
import { useTheme } from '@mui/styles';
import AppArticle from 'components/new_components/AppArticle';
// import CisoReport from './CisoReport/CisoReport';
import { removeSpaces } from 'utils';
import ViewThreatDrawer from './components/firstSection/ViewThreatIntelligence';
// import { ActivateAddOn } from 'store/actions/generalActions';
import { toast } from 'react-toastify';
import { GetAddOns } from 'store/actions/generalActions';
import SecondSection from './components/SecondSection/SecondSection';
import ThirdSection from './components/ThirdSection/ThirdSection';
import { UpdateCompanyDetails } from 'store/actions/merchantActions';
import { GetSmartIntel } from 'store/actions/generalActions';
import { GetPeopleSummary } from 'store/actions/generalActions';
import SmartIntelBanner from 'assets/img/smartIntel/Dashboard__ smart intelligence.svg';
import ArrowUp from 'assets/img/smartIntel/Vector.svg';
import Warning from 'assets/img/smartIntel/Vector (1).svg';
import CloseCircleIcon from 'assets/img/smartIntel/Frame 2610148.svg';
import Buttons from 'assets/img/smartIntel/buttons.svg';
import EastIcon from '@mui/icons-material/East';
import RequestSuccessfulIcon from 'assets/img/smartIntel/Request_sent.svg';
import { RequestAddOn } from 'store/actions/generalActions';
import Light from 'assets/img/Light.svg';
import CloseIcon from 'assets/img/smartIntel/Icon Button.svg';
import ViewAllThreatIntelligence from './components/ViewAllThreatIntelligence';
import { GetAllVendors } from 'store/actions/vendorActions';
import { GetAllInfrastructures } from 'store/actions/merchantActions';
import { GetRiskManagementSettings } from 'store/actions/riskAssessmentActions';
import { GetSmartComplianceReport } from 'store/actions/dashboardActions';
import { GetSmartComplianceAIRecommendations } from 'store/actions/dashboardActions';
import ThreatViewModal from './components/ThreatViewModal';
import CisoReport from './newCisoReport/NewCisoReport';
import { getType } from 'utils';
import { GetDefaultKeywords } from 'store/actions/merchantActions';
import { CloseOutlined } from '@ant-design/icons';

const Dashboard = () => {
    const [switch_on, setSwitch_on] = useState(false);
    const [suggestionsModalOpen, setSuggestionsModalOpen] = useState(false);
    const [loadingState, setLoadingState] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openTour, setOpenTour] = useState(false);
    const [openArticle, setOpenArticle] = useState(false);
    const [openRequest, setOpenRequest] = useState(false);
    const [currentData, setCurrentData] = useState(null);
    const [feedSettingsOpen, setFeedSettingsOpen] = useState(false);
    const [openAllIntelligence, setOpenAllInteggligence] = useState(false);
    const [currentTag, setCurrentTag] = useState('');
    const [type, setType] = useState('');
    const [openCiso, setOpenCiso] = useState(false);
    const theme = useTheme();

    const handleOpen = (data, type, tag) => {
        setCurrentTag(tag);
        setType(type);
        setCurrentData(data);
        setOpenDrawer(true);
    };

    // const onChangeView = () => {
    //     // setView('ciso-report');
    //     setOpenCiso(true);
    // };

    const { updateOneUserGuide, toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);

    const dispatch = useDispatch();
    const merchant_info = useSelector((state) => state?.merchantReducers?.merchant_info);
    const { smartComplianceReport, ai_recommendations } = useSelector((state) => state?.dashboardReducers);
    const userguide_info = useSelector((state) => state?.guideReducers?.userguide_info?.[0]?.compliance_health);
    const organization = useSelector((state) => state?.generalReducers?.user_info?.organization);
    const { all_addons } = useSelector((state) => state?.generalReducers);
    const [viewThreat, setViewThreat] = useState(false);
    const [currentThreat, setCurrentThreat] = useState(null);

    const getMerchantSummary = async () => {
        setLoadingState(true);
        await dispatch(GetSmartComplianceReport());
        setLoadingState(false);
        await dispatch(GetSmartComplianceAIRecommendations());
        await dispatch(GetMerchantNDPRGeneralSummary());
        await dispatch(GetMerchantPcidss4point0OverviewSummary());
        await dispatch(GetMerchantKDPAGeneralSummary());
        await dispatch(GetMerchantPCISLCGeneralSummary());
        await dispatch(GetMerchantSoc2GeneralSummary());
        await dispatch(GetMerchantOverviewSummary());
        await dispatch(GetDashboardCompliance());
        await dispatch(GetAddOns());
        await dispatch(GetSmartIntel());
        await dispatch(GetPeopleSummary());
        await dispatch(GetAllVendors());
        await dispatch(GetAllInfrastructures());
        await dispatch(GetRiskManagementSettings());
    };
    useEffect(() => {
        getMerchantSummary();
    }, []);

    const openSuggestionsModal = () => {
        setSuggestionsModalOpen(true);
    };
    const closeSuggestionsModal = () => {
        setSuggestionsModalOpen(false);
    };
    //UserGuide
    const OpenUserGuide = () => {
        setOpenTour(true);
    };
    const CloseUserGuide = () => {
        updateOneUserGuide({ compliance_health: true });
        setToggleUserGuide({ ...toggleUserGuide, compliance_health: false });
        setOpenTour(false);
    };

    const OpenArticle = () => {
        setOpenArticle(true);
    };
    const CloseArticle = () => {
        setToggleUserGuide({ ...toggleUserGuide, dashboard: false });
        setOpenArticle(false);
    };

    useEffect(() => {
        if (typeof userguide_info === 'undefined') return;

        !userguide_info && !toggleUserGuide?.compliance_health
            ? OpenUserGuide()
            : toggleUserGuide.dashboard
            ? OpenArticle()
            : null;
    }, [userguide_info, toggleUserGuide]);

    // memos
    const standards = useMemo(() => {
        const paidStandards = removeSpaces(organization?.paid_standards)?.toLowerCase();
        const standards = removeSpaces(organization?.standards)?.toLowerCase();
        return standards?.split(',')?.filter((standard) => paidStandards?.includes(standard));
    }, [organization]);
    //guuide shenanigans
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const steps = [
        {
            title: (
                <Stack direction="row" alignItems="center" gap={1}>
                    <img src="/img/dashboard-ai-white.svg" className="w-[36px]" alt="Dashboard AI icon" />
                    <Box>
                        <Typography sx={{ fontSize: 11, fontWeight: 600, color: theme.palette.neutral[30] }}>
                            Vifly
                        </Typography>
                        <Typography sx={{ fontSize: 10, fontWeight: 300, color: theme.palette.neutral[60] }}>
                            SmartComply AI
                        </Typography>
                    </Box>
                </Stack>
            ),
            description: (
                <>
                    <Typography sx={{ fontSize: 12, fontWeight: 600, color: theme.palette.neutral[50] }}>
                        Hello {merchant_info?.name} 👋
                    </Typography>
                    <Typography sx={{ fontSize: 12, fontWeight: 400, color: theme.palette.neutral[50] }}>
                        Compliance health indicates the areas that require your attention. The more attention you can
                        address, the greater your level of compliance.
                    </Typography>
                </>
            ),
            target: () => ref1.current,
            nextButtonProps: {
                children: 'Next',
                onClick: () => openSuggestionsModal(),
                style: { fontSize: '10px !important', width: 'fit-content !important' },
            },
        },
        {
            title: (
                <Stack direction="row" alignItems="center" gap={1}>
                    <img src="/img/dashboard-ai-white.svg" className="w-[36px]" alt="Dashboard AI icon" />
                    <Box>
                        <Typography sx={{ fontSize: 11, fontWeight: 600, color: theme.palette.neutral[30] }}>
                            Vifly
                        </Typography>
                        <Typography sx={{ fontSize: 10, fontWeight: 300, color: theme.palette.neutral[60] }}>
                            SmartComply AI
                        </Typography>
                    </Box>
                </Stack>
            ),
            description: (
                <>
                    <Typography sx={{ fontSize: 12, fontWeight: 600, color: theme.palette.neutral[50], mb: 2 }}>
                        Compliance health summary👋
                    </Typography>
                    <Typography sx={{ fontSize: 12, fontWeight: 400, color: theme.palette.neutral[50] }}>
                        Click on the <strong>Compliance health tab,</strong> to view a rundown of areas that need
                        attention.
                    </Typography>
                </>
            ),
            target: () => ref2.current,
            prevButtonProps: {
                children: 'Prev',
                onClick: () => closeSuggestionsModal(),
            },
            nextButtonProps: {
                children: 'Next',
                onClick: () => closeSuggestionsModal(),
                style: { fontSize: '10px !important', width: 'fit-content !important' },
            },
        },
        {
            title: (
                <Stack direction="row" alignItems="center" gap={1}>
                    <img src="/img/dashboard-ai-white.svg" className="w-[36px]" alt="Dashboard AI icon" />
                    <Box>
                        <Typography sx={{ fontSize: 11, fontWeight: 600, color: theme.palette.neutral[30] }}>
                            Vifly
                        </Typography>
                        <Typography sx={{ fontSize: 10, fontWeight: 300, color: theme.palette.neutral[60] }}>
                            SmartComply AI
                        </Typography>
                    </Box>
                </Stack>
            ),
            description: (
                <>
                    <Typography sx={{ fontSize: 12, fontWeight: 600, color: theme.palette.neutral[50], mb: 3 }}>
                        Need more help?
                    </Typography>
                    <Typography sx={{ fontSize: 12, fontWeight: 400, color: theme.palette.neutral[50] }}>
                        With Vifly, you can get some suggestions, ask questions relating to compliance and
                        cybersecurity.
                    </Typography>
                </>
            ),
            target: () => ref3.current,
            prevButtonProps: {
                children: 'Prev',
                onClick: () => openSuggestionsModal(),
            },
        },
    ];
    const handleActivateAddon = async () => {
        if (all_addons?.[0]?.smart_intel?.status !== 'approved') {
            setOpenRequest(true);
        } else {
            if (merchant_info?.smart_intel) {
                setFeedSettingsOpen(true);
            } else {
                // setLoading(true);
                // const body = {
                //     smart_intel: merchant_info?.smart_intel ? false : true,
                // };
                // const res = await dispatch(UpdateCompanyDetails(body));
                // setLoading(false);
                // if (res?.success) {
                //     toast.success(
                //         merchant_info?.smart_intel
                //             ? 'Smart Intel Deactivated Successfully'
                //             : 'Smart Intel Activation Initiated Successfully'
                //     );
                // } else {
                //     toast.error(res?.message);
                // }
            }
        }
    };
    useEffect(() => {
        if (merchant_info) {
            setSwitch_on(merchant_info?.smart_intel);
        }
    }, [merchant_info]);

    function getPercentTag(percentage) {
        return percentage <= 40 ? 'high' : percentage <= 70 ? 'medium' : 'low';
    }
    const handleViewThreat = (option) => {
        setCurrentThreat(option);
        setViewThreat(true);
    };

    // cyber threat switch
    const userType = getType('type') === 'implementer' ? 'merchant' : getType('type');
    const role = getType('role');
    const userTypes = ['merchant', 'admin', 'aud_admin'];
    const isCyberThreatSwitchVisible = useMemo(
        () => userTypes.includes(userType) || userTypes.includes(role),
        [userType, userTypes]
    );

    return (
        <div className="">
            <div className="">
                <div className="flex justify-between items-center">
                    <h4 className="text-[#395BA9] text-lg font-bold">Overview</h4>
                    <div className="flex items-center gap-2">
                        <div
                            className={`${
                                isCyberThreatSwitchVisible ? 'flex' : 'hidden'
                            } items-center gap-2 border border-[#E2E8F0] rounded-full p-2 cursor-pointer`}
                        >
                            <CustomSwitch checked={switch_on} onChange={handleActivateAddon} />
                            {/* {loading && <Loader2 className="mr-1 h-6 w-6 animate-spin" />} */}
                            <p
                                className={`${
                                    switch_on ? 'underline pb-1 cursor-pointer' : ''
                                } text-[#475569] text-base font-medium`}
                                onClick={() => switch_on && setOpenAllInteggligence(true)}
                            >
                                Cyber Threat Intelligence
                            </p>
                            <CustomTooltip
                                title="Cyber threat intelligence is information about potential or current cyber threats that helps organizations understand the risks they face."
                                placement="top"
                            >
                                <img
                                    src="/img/risk-assessment/help-circle.svg"
                                    alt="Help Icon"
                                    className="object-contain"
                                />
                            </CustomTooltip>
                        </div>
                        {/* <p
                            className="border border-[#E2E8F0] rounded-full p-2 text-[#475569] text-sm font-medium cursor-pointer"
                            onClick={onChangeView}
                        >
                            Spool CISO Report
                        </p> */}
                    </div>
                </div>
                <FirstSection
                    openSuggestionsModal={openSuggestionsModal}
                    switch_on={switch_on}
                    handleOpen={handleOpen}
                    currentTag={currentData}
                    compliance_health_status={getPercentTag(Math.round(smartComplianceReport?.[2]?.total_percentage))}
                    compliance_health_percentage={Math.round(smartComplianceReport?.[2]?.total_percentage) || 0}
                />
                <SecondSection switch_on={switch_on} handleOpen={handleOpen} />
                <div className="2xl:block xl:hidden md:hidden">
                    <ThirdSection switch_on={switch_on} handleOpen={handleOpen} />
                </div>
                <RequestModal open={openRequest} onClose={() => setOpenRequest(false)} />
                <FeedSettings open={feedSettingsOpen} onClose={() => setFeedSettingsOpen(false)} />
                <ViewAllThreatIntelligence
                    open={openAllIntelligence}
                    handleClose={() => {
                        setCurrentData(null);
                        setOpenAllInteggligence(false);
                    }}
                    handleViewThreat={handleViewThreat}
                />
                <Tour open={openTour} mask={true} onClose={CloseUserGuide} steps={steps} />
                <AppArticle open={openArticle} handleClose={CloseArticle} title="User Guide" icon={true} />
                <ComplianceHealthSuggestions
                    open={suggestionsModalOpen}
                    handleClose={closeSuggestionsModal}
                    ref={ref2}
                    smartComplianceReport={smartComplianceReport}
                    ai_recommendations={ai_recommendations}
                    loadingState={loadingState}
                />
                <ViewThreatDrawer
                    open={openDrawer}
                    handleClose={() => {
                        setCurrentData(null);
                        setOpenDrawer(false);
                    }}
                    currentData={currentData}
                    type={type}
                    currentTag={currentTag}
                    handleViewThreat={handleViewThreat}
                />
                <ThreatViewModal
                    open={viewThreat}
                    handleClose={() => setViewThreat(false)}
                    currentThreat={currentThreat}
                />
            </div>

            <CisoReport handleClose={() => setOpenCiso(false)} open={openCiso} standards={standards} />
        </div>
    );
};

const FeedSettings = ({ open, onClose }) => {
    const [loading, setLoading] = useState(false);
    const [compliance_keywords, setComplianceKeywords] = useState([]);
    const [people_computer_keywords, setPeopleComputerKeywords] = useState([]);
    const [risk_distribution_keywords, setRiskDistributionKeywords] = useState([]);
    const [servers_keywords, setServersKeywords] = useState([]);
    const [third_party_keywords, setThirdPartyKeywords] = useState([]);
    const dispatch = useDispatch();
    const [default_keywords, setDefaultKeywords] = useState(null);

    const merchant_info = useSelector((state) => state?.merchantReducers?.merchant_info);
    const handleUpdateKeywords = async () => {
        setLoading(true);
        const body = {
            intel_keywords: {
                Compliance: compliance_keywords,
                'Third-Parties': third_party_keywords,
                'People & Computers': people_computer_keywords,
                'Risk Distribution': risk_distribution_keywords,
                Servers: servers_keywords,
            },
        };
        const res = await dispatch(UpdateCompanyDetails(body));
        setLoading(false);
        if (res?.success) {
            dispatch({ type: 'MERCHANT_INFO', payload: res.data });
            toast.success('Feed keywords updated Successfully');
            onClose();
        } else {
            toast.error(res?.message);
        }
    };

    const getDefaultKeywords = async () => {
        setLoading(true);
        const res = await dispatch(GetDefaultKeywords());
        setLoading(false);
        if (res?.success) {
            setDefaultKeywords(res?.data);
        }
    };

    useEffect(() => {
        getDefaultKeywords();
    }, []);

    useEffect(() => {
        if (merchant_info?.intel_keywords) {
            setComplianceKeywords(merchant_info?.intel_keywords?.['Compliance'] || []);
            setThirdPartyKeywords(merchant_info?.intel_keywords?.['Third-Parties'] || []);
            setPeopleComputerKeywords(merchant_info?.intel_keywords?.['People & Computers'] || []);
            setRiskDistributionKeywords(merchant_info?.intel_keywords?.['Risk Distribution'] || []);
            setServersKeywords(merchant_info?.intel_keywords?.['Servers'] || []);
        }
    }, [merchant_info]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth={true}>
            <DialogTitle className="flex justify-between items-center">
                <p className="text-[#202D66] text-xl font-semibold">Feed Settings</p>
                <img src={CloseIcon} alt="CloseIcon" className="cursor-pointer" onClick={onClose} />
            </DialogTitle>
            <DialogContent className="my-[0.5rem]">
                <DialogContentText>
                    <Stack
                        flexDirection="row"
                        sx={{
                            border: `1px solid #F0F0FA`,
                            backgroundColor: '#F8FAFC',
                            borderRadius: '4px',
                            p: '5px',
                        }}
                    >
                        <img src={Light} alt="file question" />
                        <p className="tetx-[#64748B] text-[13px] font-normal mt-2 ml-2">
                            Customize your intelligence feeds with specific keywords to ensure they are tailored to
                            deliver only relevant, organization-specific insights.
                        </p>
                    </Stack>
                    <div className="my-[1rem]">
                        <div className="flex items-center gap-2">
                            <p className="text-[#475569] text-base font-medium">Feeds keywords</p>
                            <CustomTooltip
                                title="Keywords could include;  Organizations Specific Keywords, Industry Specific Keywords, Geographic keywords, Technology keywords etc."
                                placement="top"
                            >
                                <img
                                    src="/img/risk-assessment/help-circle.svg"
                                    alt="Help Icon"
                                    className="object-contain"
                                />
                            </CustomTooltip>
                        </div>
                        <div className="my-3">
                            <p className="text-[#475569] text-base font-medium">Compliance</p>
                            <Keywords
                                keywords={compliance_keywords}
                                setKeywords={setComplianceKeywords}
                                id="Compliance"
                                default_keywords={default_keywords}
                            />
                            <p className="text-[#64748B] text-xs font-normal mt-2">
                                Use commas to separate each keyword. Keywords are not case sensitive.
                            </p>
                        </div>

                        <div className="my-3">
                            <p className="text-[#475569] text-base font-medium">People & Computers</p>
                            <Keywords
                                keywords={people_computer_keywords}
                                setKeywords={setPeopleComputerKeywords}
                                id="People & Computers"
                                default_keywords={default_keywords}
                            />
                            <p className="text-[#64748B] text-xs font-normal mt-2">
                                Use commas to separate each keyword. Keywords are not case sensitive.
                            </p>
                        </div>
                        <div className="my-3">
                            <p className="text-[#475569] text-base font-medium">Risk Distribution</p>
                            <Keywords
                                keywords={risk_distribution_keywords}
                                setKeywords={setRiskDistributionKeywords}
                                id="Risk Distribution"
                                default_keywords={default_keywords}
                            />
                            <p className="text-[#64748B] text-xs font-normal mt-2">
                                Use commas to separate each keyword. Keywords are not case sensitive.
                            </p>
                        </div>
                        <div className="my-3">
                            <p className="text-[#475569] text-base font-medium">Servers</p>
                            <Keywords
                                keywords={servers_keywords}
                                setKeywords={setServersKeywords}
                                id="Servers"
                                default_keywords={default_keywords}
                            />
                            <p className="text-[#64748B] text-xs font-normal mt-2">
                                Use commas to separate each keyword. Keywords are not case sensitive.
                            </p>
                        </div>

                        <div className="my-3">
                            <p className="text-[#475569] text-base font-medium">Third-Parties Threat</p>
                            <Keywords
                                keywords={third_party_keywords}
                                setKeywords={setThirdPartyKeywords}
                                id="Third-Parties"
                                default_keywords={default_keywords}
                            />
                            <p className="text-[#64748B] text-xs font-normal mt-2">
                                Use commas to separate each keyword. Keywords are not case sensitive.
                            </p>
                        </div>
                    </div>
                </DialogContentText>
            </DialogContent>
            <DialogActions className="bg-[#F8FAFC] flex justify-right items-center gap-2 py-4 px-3">
                <Button
                    variant="outlined"
                    sx={{
                        border: '1px solid #E2E8F0',
                        textTransform: 'inherit',
                        color: '#334155',
                        backgroundColor: '#FFFFFF',
                    }}
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    sx={{
                        textTransform: 'inherit',
                        fontWeight: 600,
                        px: 2,
                        py: 1,
                        borderRadius: 0.5,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                    }}
                    onClick={handleUpdateKeywords}
                >
                    {loading && <Loader2 className="mr-1 h-4 w-4 animate-spin" />} Update Settings
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const Keywords = ({ keywords, setKeywords, id, default_keywords }) => {
    const [open, setOpen] = useState(false);
    const [query, setQuery] = useState('');

    const filteredKeywords = useMemo(() => {
        const data = default_keywords?.[id] || [];

        return data?.filter(
            (el) =>
                el?.toLowerCase()?.includes(query?.toLowerCase()) &&
                !keywords?.some((keyword) => keyword?.toLowerCase() === el?.toLowerCase())
        );
    }, [query, keywords, id, default_keywords]);

    return (
        <div className="relative group mt-[0.5rem]">
            <div className="w-full relative flex flex-wrap gap-2 !border !border-[#E2E8F0] !rounded-md !px-2 !py-[10px] focus-within:ring-2 focus-within:ring-[#395BA9] focus-within:ring-opacity-75">
                {keywords?.map((keyword, index) => (
                    <button
                        key={index}
                        className="flex items-center gap-2 !border !border-[#F1F5F9] bg-[#F1F5F9] !rounded !py-0 !px-2 hover:brightness-90 transition-all h-6"
                        onClick={(event) => {
                            event.stopPropagation();
                            setKeywords(keywords?.filter((item) => item !== keyword));
                        }}
                    >
                        <p className="font-normal capitalize text-[13px] text-[#64748B]">{keyword}</p>

                        <img
                            src="/img/risk-assessment/x-close-small.svg"
                            alt="Delete Icon"
                            className="object-contain"
                        />
                    </button>
                ))}
                <input
                    placeholder="Add feed keywords"
                    onFocus={(event) => {
                        if (event.target.innerText === 'Add feed keywords') {
                            event.target.innerText = '';
                        }

                        if (!open) setOpen(true);

                        event.target.style.color = 'hsl(215,20%,40%)';
                    }}
                    onChange={(event) => {
                        if (event?.target?.value?.includes(',') && event.target.value.length > 2) {
                            setKeywords([...keywords, ...event.target.value.split(',')].filter(Boolean));
                            event.target.value = '';
                        }
                        setQuery(event.target.value);
                    }}
                    className="border-none text-[#94A3B8] outline-none w-full h-8"
                />
                {open ? (
                    <CloseOutlined
                        onClick={() => setOpen(false)}
                        className="text-[#000] text-lg absolute right-0 bottom-3 z-[2000] cursor-pointer"
                    />
                ) : null}
                <div className="relative w-full">
                    {open ? (
                        <ul className="absolute w-full bg-[#ffffff] overflow-y-auto h-32 z-[1000] py-2 shadow-md">
                            {filteredKeywords?.length ? (
                                filteredKeywords?.map((el, key) => (
                                    <li
                                        key={key}
                                        onClick={() => {
                                            setKeywords([...keywords, el].filter(Boolean));
                                        }}
                                        className="cursor-pointer font-normal capitalize text-[14px] p-2 px-3 text-[#64748B]"
                                    >
                                        {el}
                                    </li>
                                ))
                            ) : (
                                <p className="font-semibold text-[15px] text-center p-2 text-[#64748B]">
                                    No keywords available
                                </p>
                            )}
                        </ul>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

const RequestModal = ({ open, onClose }) => {
    const [loading, setLoading] = useState(false);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const dispatch = useDispatch();

    const handleRequestIntel = async () => {
        setLoading(true);
        const body = {
            smart_intel: true,
        };
        const res = await dispatch(RequestAddOn(body));
        setLoading(false);
        if (res?.success) {
            setOpenSuccessModal(true);
            onClose();
        } else {
            toast.error(res?.message);
        }
    };
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="lg"
            fullWidth={true}
            className="rounded-3xl"
            PaperProps={{
                sx: {
                    position: 'relative',
                    borderRadius: 5,
                },
            }}
            // style={{

            // }}
            scroll="paper"
        >
            <DialogContent className="p-0 rou">
                <div className="flex justify-between items-start">
                    <div className="bg-gradient-to-r from-[#0E2C66] to-[#1741BCD4] h-[70vh] w-[45%] relative">
                        <img src={SmartIntelBanner} alt="SmartIntelBanner" className="absolute right-0 top-[20%]" />
                        <img src={ArrowUp} alt="arrowup" className="absolute bottom-[23%] right-[30%]" />
                        <img src={Buttons} alt="Buttons" className="absolute bottom-[10%] right-[14%]" />
                    </div>
                    <div className="w-[55%] relative p-[3rem]">
                        <div className="flex items-center gap-2">
                            <img src={Warning} alt="Warning" />
                            <p className="text-[#B28C00] text-base font-medium">
                                Sorry. This feature is not part of your current subscription.
                            </p>
                        </div>
                        <div className="mt-[2rem] mb-[3rem]">
                            <h4 className="text-[#334155] text-[36px] font-bold">Threat Intel</h4>
                            <div className="mt-[1.5rem]">
                                <p className="text-[#64748B] text-base font-medium mb-1">
                                    Stay ahead of emerging threats with real-time insights into malicious activities,
                                    vulnerabilities, and attack patterns.
                                </p>
                                <p className="text-[#64748B] text-base font-medium">
                                    Leverage advanced analytics and global threat feeds to proactively defend your
                                    critical assets, reduce risk, and stay one step ahead of attackers in an
                                    ever-evolving threat landscape.
                                </p>
                            </div>
                        </div>
                        <p className="text-[#64748B] text-[13px] font-normal mb-1">
                            Want this feature? Give us a beep by clicking the button below
                        </p>
                        <Button
                            variant="contained"
                            sx={{
                                textTransform: 'inherit',
                                fontWeight: 600,
                                px: 2,
                                py: 1.3,
                                display: 'flex',
                                alignItems: 'center',
                                borderRadius: 2,
                                gap: 1.5,
                                mt: 1,
                                fontSize: '16px',
                            }}
                            onClick={handleRequestIntel}
                        >
                            {loading && <Loader2 className="h-6 w-6 animate-spin" />} Send Request
                            <EastIcon />
                        </Button>
                    </div>
                    <img
                        src={CloseCircleIcon}
                        alt="Close"
                        className="absolute top-[-3%] right-[-3%] cursor-pointer"
                        onClick={onClose}
                        style={{
                            zIndex: 100000000000,
                        }}
                    />
                </div>
            </DialogContent>

            <RequestSuccesfulModal
                open={openSuccessModal}
                onClose={() => {
                    onClose();
                    setOpenSuccessModal(false);
                }}
            />
        </Dialog>
    );
};

const RequestSuccesfulModal = ({ open, onClose }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth={true}
            scroll="paper"
            PaperProps={{
                sx: {
                    borderRadius: 3,
                    width: '350px',
                },
            }}
        >
            <DialogContent className="flex justify-center items-center flex-col my-[1.5rem]">
                <img src={RequestSuccessfulIcon} alt="RequestSuccessfulIcon" />
                <DialogContentText className="mt-3">
                    <p className="text-[#37A372] text-lg font-semibold text-center">Request Sent</p>
                    <p className="text-[#64748B] text-[13px] normal text-center">
                        We will reach out to you for further <br />
                        inquiries.
                    </p>
                </DialogContentText>
            </DialogContent>
            <div className="bg-[#F8FAFC] flex justify-center items-center py-4">
                <Button
                    variant="outlined"
                    sx={{
                        border: '1px solid #E2E8F0',
                        textTransform: 'inherit',
                        color: '#334155',
                        backgroundColor: '#FFFFFF',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    onClick={onClose}
                >
                    Close
                </Button>
            </div>
        </Dialog>
    );
};

export default Dashboard;
