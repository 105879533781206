import React, { useEffect, useState } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { FiTrash2 } from 'react-icons/fi';

//redux
import { connect, useDispatch } from 'react-redux';

//core components
import AppViewModal from 'components/new_components/AppViewModal';
import { GetMerchantDocumentVersions } from 'store/actions/complianceActions';
import { toast } from 'react-toastify';
import FileTypes from 'assets/img/Filetypes.svg';
import Docx from 'assets/img/docx.svg';
import Png from 'assets/img/png.svg';
import Xsl from 'assets/img/xsl.svg';
import Img from 'assets/img/img.svg';
import Download from 'assets/img/download01.svg';
import moment from 'moment';
import { DeleteMerchantDocument } from 'store/actions/complianceActions';
import LoadingState from 'components/new_components/LoadingState';
import AppDeleteModal from 'components/new_components/AppDeleteModal';
import { getType } from 'utils';

//translations
import { useTranslation } from 'react-i18next';
import { GetDocumentLink } from 'store/actions/complianceActions';

const PolicyHistoryModal = (props) => {
    //props
    const {
        open,
        handleClose,
        GetMerchantDocumentVersions,
        document,
        document_versions,
        doc_id,
        DeleteMerchantDocument,
    } = props;

    // state
    const [loading, setLoading] = useState({ versions: false, messages: false, delete: false });
    const [policy_id, setPolicyid] = useState();
    const [deleteModal, setDeleteModal] = useState(false);
    const role = getType('role');
    const [loadingLink, setLoadingLink] = useState(false);
    const dispatch = useDispatch();

    const handleDeleteOpen = (row) => {
        setPolicyid(row);
        setDeleteModal(true);
    };

    const handleDeleteClose = () => {
        setPolicyid(null);
        setDeleteModal(false);
        handleClose();
    };

    //translation
    const { t } = useTranslation('compliance');

    // mui
    const theme = useTheme();

    // async functions
    const getDocumentVersions = async () => {
        setLoading({ ...loading, versions: true });
        const res = await GetMerchantDocumentVersions(document?.doc_id);
        setLoading({ ...loading, versions: false });
        if (!res?.success && document?.doc_id !== undefined) {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        if (open && document?.doc_id === doc_id) {
            getDocumentVersions();
        }
    }, [open, document, doc_id]);

    const handleVersionDelete = async () => {
        setLoading({ ...loading, delete: true });
        const res = await DeleteMerchantDocument(policy_id.id, policy_id.document_id);
        setLoading({ ...loading, false: true });
        if (res?.success) {
            toast.success('Version deleted successfuly!');
            handleDeleteClose();
        } else {
            toast.error('Something went wrong!');
            console.log(res?.message);
        }
    };

    const getDocumentLink = async (id) => {
        setLoadingLink(true);
        const res = await dispatch(GetDocumentLink('userdoc', id));
        setLoadingLink(false);
        if (res?.success) {
            window.open(res?.data, '_blank');
        } else {
            toast.error('Something went wrong!');
        }
    };
    return (
        <AppViewModal open={open} handleClose={handleClose} width={'520px !important'}>
            <Box sx={{ my: 1.5 }}>
                <Box sx={{ display: 'flex', mx: 3, pb: 2, alignItems: 'center' }}>
                    <KeyboardBackspaceIcon
                        onClick={handleClose}
                        sx={{ width: 20, height: 20, color: '#000000', cursor: 'pointer' }}
                    />
                    <Typography sx={{ fontSize: '16px', fontWeight: 600, color: '#202D66', ml: 2 }}>
                        Policy History
                    </Typography>
                </Box>
                <Divider sx={{ color: theme.palette.neutral[95] }} />
            </Box>
            {loading?.versions ? (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 10 }}>
                    <LoadingState />
                </Box>
            ) : (
                <Box sx={{ mt: 2, mx: 3 }}>
                    {document_versions?.map((version) => (
                        <Box key={version?.id} sx={{ py: 1 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <img
                                        src={
                                            version?.document?.split('.').pop() === 'xlsx'
                                                ? Xsl
                                                : version?.document?.split('.').pop() === 'docx'
                                                ? Docx
                                                : version?.document?.split('.').pop() === 'png'
                                                ? Png
                                                : version?.document?.split('.').pop() === 'img'
                                                ? Img
                                                : FileTypes
                                        }
                                        alt="FileTypes"
                                    />
                                    <Box sx={{ ml: 2 }}>
                                        <Typography
                                            component="p"
                                            sx={{
                                                color: '#475569',
                                                fontSize: '14px',
                                                fontWeight: 400,
                                                maxWidth: '300px !important',
                                                wordWrap: 'break-word',
                                            }}
                                        >
                                            {version?.document?.split('/')?.pop()}
                                        </Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                            <Typography sx={{ color: '#64748B', fontSize: '12px', fontWeight: 400 }}>
                                                {moment(version?.date_created)?.format('lll')}
                                            </Typography>

                                            {version?.approved_status === 'declined' && (
                                                <Typography
                                                    sx={{
                                                        backgroundColor: '#FFEDEA',
                                                        color: '#DE3730',
                                                        fontSize: '12px',
                                                        fontWeight: 400,
                                                        ml: 1.5,
                                                    }}
                                                >
                                                    Rejected
                                                </Typography>
                                            )}

                                            {version?.approved_status === 'pending' && (
                                                <Typography
                                                    sx={{
                                                        backgroundColor: '#F1F5F9',
                                                        color: '#B28C00',
                                                        fontSize: '12px',
                                                        fontWeight: 400,
                                                        ml: 1.5,
                                                    }}
                                                >
                                                    Awaiting Approval
                                                </Typography>
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Box
                                        // component="a"
                                        // href={version?.document}
                                        // rel="noreferrer"
                                        // target="_blank"
                                        onClick={() => getDocumentLink(version?.id)}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            mr: 2,
                                        }}
                                    >
                                        {' '}
                                        {loadingLink ? (
                                            <LoadingState size={20} />
                                        ) : (
                                            <img src={Download} alt={'Download'} />
                                        )}
                                    </Box>
                                    {role !== 'auditor' && (
                                        <FiTrash2
                                            color="#F43F5E"
                                            sx={{ cursor: 'pointer' }}
                                            onClick={() => handleDeleteOpen(version)}
                                        />
                                    )}
                                </Box>
                            </Box>
                            <Divider sx={{ color: theme.palette.neutral[95], mt: 1, mx: '-2rem' }} />
                        </Box>
                    ))}
                </Box>
            )}

            <AppDeleteModal
                open={deleteModal}
                handleClose={handleDeleteClose}
                title={t('title')}
                subtitle={t('subtitle')}
                deleting={loading?.delete}
                onDelete={handleVersionDelete}
            />
        </AppViewModal>
    );
};

const mapStateToProps = (state) => {
    return {
        document_versions: state?.complianceReducers?.merchant_document_versions,
    };
};
export default connect(mapStateToProps, { GetMerchantDocumentVersions, DeleteMerchantDocument })(PolicyHistoryModal);
