import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Stack, Box } from '@mui/material';

// core components
import AuthViewContainer from './components/AuthViewContainer';
import { AppForm, AppFormInput, AppSubmitButton } from 'components/new_components/forms';
import PageHeader from 'components/new_components/PageHeader';

//i18n
import { useTranslation } from 'react-i18next';

// validation
import { loginValidation } from './utils/authValidation';

// redux
import { LoginUser } from 'store/actions/authActions';
import { connect, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { LogoutUser } from 'store/actions/authActions';
import { OpenAutoRiskAction } from 'store/actions/riskAssessmentActions';

const Login = (props) => {
    const { LoginUser, history, location, LogoutUser } = props;
    const theme = useTheme();
    // state
    const [loading, setLoading] = useState(false);
    // memo
    const error = useMemo(() => location?.state?.error, []);
    const dispatch = useDispatch();
    const { t } = useTranslation('auth');

    const handleSubmit = async (values) => {
        setLoading(true);
        const res = await LoginUser(values);
        setLoading(false);
        if (res?.success) {
            dispatch(OpenAutoRiskAction(true));
            const { user_type, mfa, mfa_type, email, first_login, role } = res;
            if (first_login) {
                window.location.assign('/auth/change-password');
            } else {
                if (mfa && (user_type !== 'admin' || user_type !== 'support')) {
                    toast.info('Almost there, Kindly, Confirm OTP');
                    setTimeout(() => {
                        history.push('/auth/confirm-otp', { mfa_type, user_type, role, email });
                    }, 2000);
                } else {
                    setTimeout(() => {
                        const redirectTo =
                            user_type === 'merchant' || user_type === 'employee' || user_type === 'support'
                                ? '/merchant/index'
                                : user_type === 'admin'
                                ? '/admin'
                                : user_type === 'implementer'
                                ? '/implementer/clients'
                                : '/auditor';
                        history.push(redirectTo);
                    }, 2000);
                }
            }
        } else {
            toast.error(res?.message || "Something went wrong, we couldn't validate your credentials. Try again!");
        }
    };
    // useEffects
    useEffect(() => {
        if (history.action === 'REPLACE') {
            toast.info(error);
        }
    }, [error]);

    const logout = async () => {
        await LogoutUser();
    };

    const clearCacheData = () => {
        console.log(caches);
        if ('caches' in window) {
            caches.keys().then((names) => {
                names.forEach((name) => {
                    console.log(name);
                    caches.delete(name);
                });
            });
        }
    };

    useEffect(() => {
        clearCacheData();
        logout();
    }, []);

    return (
        <>
            <PageHeader browserTitle="Sign in | Smartcomply" />
            <AuthViewContainer
                title={t('login.header')}
                // subtitle={t('login.subheader')}
                altLink={{ text: t('altLinkText'), to: '/auth/add-merchant' }}
                sx={{ width: { xs: '100%', md: '50%' }, mx: 'auto' }}
                altLink2={t('altLinkText2')}
            >
                <AppForm
                    initialValues={{ email: '', password: '' }}
                    onSubmit={handleSubmit}
                    validate={(values) => loginValidation(values)}
                >
                    {/* <AppFormInput
                        type="email"
                        label="Email Address"
                        placeholder="Email Address"
                        medium
                        variant={'standard'}
                        name="email"
                    /> */}
                    <AppFormInput type="email" label={t('login.inputLabel1')} name="email" large variant={'standard'} />
                    <AppFormInput
                        type="password"
                        label={t('login.inputLabel2')}
                        placeholder="••••••••"
                        name="password"
                    />
                    <Stack direction="row" justifyContent="flex-end">
                        <Box
                            component={Link}
                            to="/auth/forgot-password"
                            sx={{
                                color: theme.palette.primary[900],
                                fontWeight: 400,
                                my: 1.5,
                                fontSize: '12px',
                            }}
                        >
                            {t('login.forgotPassword')}
                        </Box>
                    </Stack>
                    <AppSubmitButton
                        text={t('login.header')}
                        variant="contained"
                        color="primary"
                        loadingPosition="center"
                        sx={{ width: '100%', mt: 1, padding: '12px 8px 12px 8px' }}
                        loading={loading}
                    />
                </AppForm>
            </AuthViewContainer>
        </>
    );
};

const mapStateToProps = (state) => {
    return { ...state };
};

export default connect(mapStateToProps, { LoginUser, LogoutUser })(Login);
